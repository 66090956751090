<template>
    <Button label="Kembali" icon="pi pi-angle-left" class="p-button-link p-button-rounded p-button-success mb-2" @click="this.$router.go(-1)"/>
	<Toast />
	<ConfirmDialog></ConfirmDialog>
	<div class="fadein animation-duration-300">
		<Accordion v-model:activeIndex="activeTab">
			<AccordionTab header="Pencarian">
				<div class="p-fluid grid formgrid">
					<div class="field col-12 md:col-4">
						<label for="tahunInput">Pilih tahun</label>
						<Calendar id="tahunInput" palceholder="Pilih Tahun" v-model="tahunSelect" view="year" dateFormat="yy" @date-select="tahunOk()" :manualInput="false" :showButtonBar="true" @clear-click="reset()" @today-click="hariIni()" />
					</div>
					<div class="field col-12 md:col-4" v-if="tahunSelected">
						<label for="bulanInput">Pilih bulan</label>
						<Calendar id="bulanInput" palceholder="Pilih Bulan" v-model="bulanSelect" view="month" dateFormat="MM" @date-select="bulanOk()" :minDate="minMonth" :maxDate="maxMonth" :manualInput="false" :showButtonBar="true" @clear-click="() => {this.bulanSelect = null; this.bulan = null; this.bulanSelected = false; this.tanggalSelect = null; this.tanggal = null;}" @today-click="hariIni()" />
					</div>
					<div class="field col-12 md:col-4" v-if="bulanSelected">
						<label for="tanggalInput">Pilih tanggal</label>
						<Calendar id="tanggalInput" palceholder="Pilih Tanggal" v-model="tanggalSelect" view="date" dateFormat="d" @date-select="tanggalOk()" :minDate="minDate" :maxDate="maxDate" :manualInput="false" :showButtonBar="true" @clear-click="() => {this.tanggalSelect = null; this.tanggal = null;}" @today-click="hariIni()" />
					</div>
				</div>
				<div class="flex justify-content-between flex-column sm:flex-row">
					<Button type="button" label="Cari Semua" icon="pi pi-search" :loading="loading" @click="search()" v-if="!tahunSelect && !bulanSelect && !tanggalSelect"/>
					<Button type="button" label="Cari Berdasarkan Tahun" icon="pi pi-search" :loading="loading" @click="search()" v-if="tahunSelect && !bulanSelect && !tanggalSelect"/>
					<Button type="button" label="Cari Berdasarkan Bulan" icon="pi pi-search" :loading="loading" @click="search()" v-if="tahunSelect && bulanSelect && !tanggalSelect"/>
					<Button type="button" label="Cari Berdasarkan Tanggal" icon="pi pi-search" :loading="loading" @click="search()" v-if="tahunSelect && bulanSelect && tanggalSelect"/>
					<Button label="Reset pencarian" icon="pi pi-times" class="p-button-rounded p-button-text" @click="reset()" />
				</div>
			</AccordionTab>
			<AccordionTab header="Daftar Donasi">
				<h5>Donasi Terkumpul</h5>
				<small class="mt-auto mb-auto"><i>*pilih untuk melihat mencetak kwitansi</i></small>
				<DataTable :value="listDonasi" :paginator="true" class="p-datatable-gridlines" :rows="10" :rowsPerPageOptions="[10,20,30]" dataKey="id" :rowHover="true" 
							v-model:filters="filters1" filterDisplay="row" :loading="loading" :filters="filters1" responsiveLayout="scroll" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Menampilkan donasi {first}-{last} dari {totalRecords} donasi"
							:globalFilterFields="['nama_donatur', 'id', 'nominal']" stripedRows
							selectionMode="single" @rowSelect="cetakKwitansi" v-model:selection="selectedDonasi"
							sortField="id" :sortOrder="-1">
					
					<template #header>
						<div class="flex justify-content-between flex-column sm:flex-row">
							<Button type="button" icon="pi pi-filter-slash" label="Reset Pencarian" class="p-button-outlined mb-2" @click="clearFilter1()"/>
							<span class="p-input-icon-left mb-2">
								<i class="pi pi-search" />
								<InputText v-model="filters1['global'].value" placeholder="Cari" style="width: 100%"/>
							</span>
						</div>
					</template>
					<template #empty>
						Daftar donasi tidak ditemukan...
					</template>
					<template #loading>
						Memuat daftar donasi. Mohon menunggu...
					</template>
					<Column field="id" header="Nomor Kwitansi" style="max-width:11rem" :sortable="true" :showFilterMenu="false" >
						<template #body="{data}">
							{{noKwitansi(data.id)}}
						</template>
						<template #filter="{filterModel,filterCallback}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="No. Kwitansi" @keydown.enter="filterCallback()" v-tooltip.top.focus="'Tekan enter untuk mencari'"/>
						</template>
					</Column>
					<Column field="tgl_donasi" dataType="date" header="Tanggal Donasi" style="max-width:11rem" :sortable="true" :showFilterMenu="false" >
						<template #body="{data}">
							{{formatDate(data.tgl_donasi)}}
						</template>
						<template #filter="{filterModel,filterCallback}">
							<Calendar v-model="filterModel.value" dateFormat="dd M yy" class="p-column-filter" placeholder="Tgl" @date-select="filterCallback()" :manualInput="false" />
						</template>
					</Column>
					<Column field="nama_donatur" header="Nama Donatur" style="min-width:12rem" :sortable="true">
						<template #body="{data}">
							{{data.nama_donatur}}
						</template>
						<template #filter="{filterModel,filterCallback}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari nama donatur" @keydown.enter="filterCallback()" v-tooltip.top.focus="'Tekan enter untuk mencari'"/>
						</template>
					</Column>
					<Column field="nominal" header="Nominal" dataType="numeric" style="min-width:12rem" :sortable="true">
						<template #body="{data}">
							{{formatCurrency(data.nominal)}}
						</template>
						<template #filter="{filterModel,filterCallback}">
							<InputNumber v-model="filterModel.value" mode="currency" currency="IDR" locale="id-ID" placeholder="Input Nominal" @keydown.enter="filterCallback()" :maxFractionDigits="0" v-tooltip.top.focus="'Tekan enter untuk mencari'"/>
						</template>
					</Column>
					<Column field="keterangan" header="Keterangan" bodyClass="text-center" style="min-width:8rem" :sortable="true" >
						<template #body="{data}">
							{{data.keterangan}}
						</template>
						<template #filter="{filterModel,filterCallback}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Keterangan" @keydown.enter="filterCallback()" v-tooltip.top.focus="'Tekan enter untuk mencari'"/>
						</template>
					</Column>
					<Column field="nama_amil" header="Amil" style="min-width:12rem" :sortable="true" v-if="(this.$store.state.user.role == 'Super Admin' || this.$store.state.user.role == 'Admin')">
						<template #body="{data}">
							{{data.nama_amil}}
						</template>
						<template #filter="{filterModel,filterCallback}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari nama donatur" @keydown.enter="filterCallback()" v-tooltip.top.focus="'Tekan enter untuk mencari'"/>
						</template>
					</Column>
				</DataTable>
			</AccordionTab>
		</Accordion>
	</div>
</template>

<script>
	import {FilterMatchMode} from 'primevue/api';

	import Donasi from '../api/Donasi'

	export default {
		name: 'Amil',
        created() {
			this.Donasi = new Donasi();
        },
		data() {
			return {
				listDonasi: null,
				tanggalSelect: null,
				bulanSelect: null,
				tahunSelect: null,
				tanggal: this.$store.state.tanggal,
				bulan: this.$store.state.bulan,
				tahun: this.$store.state.tahun,
				tahunSelected: false,
				bulanSelected: false,
				minDate: null,
				maxDate: null,
				minMonth: null,
				maxMonth: null,
				selectedDonasi: null,
				activeTab: 1,
				filters1: {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'id': {value: null, matchMode: FilterMatchMode.EQUALS},
					'tgl_donasi': {value: null, matchMode: FilterMatchMode.DATE_IS},
					'nama_donatur': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nominal': {value: null, matchMode: FilterMatchMode.EQUALS},
					'keterangan': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nama_amil': {value: null, matchMode: FilterMatchMode.CONTAINS},
				},
				loading: false,
				checked: true,
			}
		},
		
		mounted() {
			this.loading = true;
			this.init();
		},
		methods: {
			noKwitansi(value) {
                return ('0000000' + value).substr(-8);
            },
			tanggalOk() {
				this.tanggal = this.tanggalSelect.getDate();
			},
			bulanOk() {
				this.bulanSelected = true;
				this.bulan = this.bulanSelect.getMonth()+1;
				this.minDate = new Date();
				this.minDate.setFullYear(this.tahun);
				this.minDate.setMonth(this.bulan-1);
				this.minDate.setDate(1);
				this.maxDate = new Date();
				this.maxDate.setFullYear(this.tahun);
				this.maxDate.setMonth(this.bulan);
				this.maxDate.setDate(0);
				this.tanggalSelect = null;
				this.tanggal = null;
			},
			tahunOk() {
				this.tahun = this.tahunSelect.getFullYear();
				this.minMonth = new Date();
				this.minMonth.setFullYear(this.tahun);
				this.minMonth.setMonth(0);
				this.maxMonth = new Date();
				this.maxMonth.setFullYear(this.tahun);
				this.maxMonth.setMonth(11);
				this.tanggalSelect = null;
				this.bulanSelect = null;
				this.tanggal = null;
				this.bulan = null;
				this.bulanSelected = false;
				this.tahunSelected = true;
			},
			search() {
				this.loading = true;
				this.activeTab = 1
				if (!this.tahun && !this.bulan && !this.tanggal) {
					this.Donasi.getList()
						.then(response => {
							this.listDonasi = response.data.donasi
							this.loading = false;
							this.listDonasi.forEach(donasi => donasi.tgl_donasi = new Date(donasi.tgl_donasi));
						})
						.catch(error => {
							this.networkError()
						})
				} else if (this.tahun && !this.bulan && !this.tanggal) {
					this.Donasi.getYear(this.tahun)
						.then(response => {
							this.listDonasi = response.data.donasi
							this.loading = false;
							this.listDonasi.forEach(donasi => donasi.tgl_donasi = new Date(donasi.tgl_donasi));
						})
						.catch(error => {
							this.networkError()
						})
				} else if (this.tahun && this.bulan && !this.tanggal) {
					this.Donasi.getMonth(this.tahun, this.bulan)
						.then(response => {
							this.listDonasi = response.data.donasi
							this.loading = false;
							this.listDonasi.forEach(donasi => donasi.tgl_donasi = new Date(donasi.tgl_donasi));
						})
						.catch(error => {
							this.networkError()
						})
				} else if (this.tahun && this.bulan && this.tanggal) {
					this.Donasi.getDay(this.tahun, this.bulan, this.tanggal)
						.then(response => {
							this.listDonasi = response.data.donasi
							this.loading = false;
							this.listDonasi.forEach(donasi => donasi.tgl_donasi = new Date(donasi.tgl_donasi));
						})
						.catch(error => {
							this.networkError()
						})
				}
			},
			init() {
				this.loading = true;
				this.activeTab = 1
				if (!this.tahun && !this.bulan && !this.tanggal) {
					this.Donasi.getList()
						.then(response => {
							this.listDonasi = response.data.donasi
							this.loading = false;
							this.listDonasi.forEach(donasi => donasi.tgl_donasi = new Date(donasi.tgl_donasi));
						})
						.catch(error => {
							console.log(this.listDonasi);
						})
				} else if (this.tahun && !this.bulan && !this.tanggal) {
					this.Donasi.getYear(this.tahun)
						.then(response => {
							this.listDonasi = response.data.donasi
							this.loading = false;
							this.listDonasi.forEach(donasi => donasi.tgl_donasi = new Date(donasi.tgl_donasi));
						})
						.catch(error => {
							this.networkError()
						})
				} else if (this.tahun && this.bulan && !this.tanggal) {
					this.Donasi.getMonth(this.tahun, this.bulan)
						.then(response => {
							this.listDonasi = response.data.donasi
							this.loading = false;
							this.listDonasi.forEach(donasi => donasi.tgl_donasi = new Date(donasi.tgl_donasi));
						})
						.catch(error => {
							this.networkError()
						})
				} else if (this.tahun && this.bulan && this.tanggal) {
					this.Donasi.getWeek(this.tahun, this.bulan, this.tanggal)
						.then(response => {
							this.listDonasi = response.data.donasi
							this.loading = false;
							this.listDonasi.forEach(donasi => donasi.tgl_donasi = new Date(donasi.tgl_donasi));
						})
						.catch(error => {
							this.networkError()
						})
				}
				this.$store.commit("deleteTanggal");
				this.$store.commit("deleteBulan");
				this.$store.commit("deleteTahun");
			},
			reset() {
				this.tanggalSelect = null;
				this.bulanSelect = null;
				this.tahunSelect = null;
				this.tanggal = null;
				this.bulan = null;
				this.tahun = null;
				this.tahunSelected = false;
				this.bulanSelected = false;
				this.minDate = null;
				this.maxDate = null;
				this.minMonth = null;
				this.maxMonth = null;
			},
			hariIni() {
				const today = new Date();
				this.tanggal = today.getDate();
				this.bulan = today.getMonth()+1;
				this.tahun = today.getFullYear();
				this.tanggalSelect = today;
				this.bulanSelect = today;
				this.tahunSelect = today;
				this.tahunSelected = true;
				this.bulanSelected = true;
			},
            cetakKwitansi(event) {
				this.$router.push({name: 'Kwitansi', params: { idDonasi: event.data.id} })
            },
			clearFilter1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'id': {value: null, matchMode: FilterMatchMode.EQUALS},
					'tgl_donasi': {value: null, matchMode: FilterMatchMode.DATE_IS},
					'nama_donatur': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nominal': {value: null, matchMode: FilterMatchMode.EQUALS},
					'keterangan': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nama_amil': {value: null, matchMode: FilterMatchMode.CONTAINS},
				}
			},
			formatDate(value) {
				return value.toLocaleDateString('id-ID', {
					day: '2-digit',
					month: 'short',
					year: 'numeric',
				});
			},
			formatCurrency(value) {
				var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
                    maximumFractionDigits: 0,
				});
				return formatter.format(value);
			},
			
                        



			networkError() {
                this.$confirm.require({
                    message: 'Telah terjadi kesalahan. Coba refresh halaman.',
                    header: 'Error',
                    icon: 'pi pi-exclamation-triangle',
                    blockScroll: true,
                    defaultFocus: "accept",
                    accept: () => {
                        //callback to execute when user confirms the action
                        this.$router.go()
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                        this.$confirm.close();
                    }
                });
            },
		}
	}
</script>
